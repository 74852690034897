<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <v-row>
          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Asset Number" rules="required">
              <v-text-field
                v-model="batteries.asset_number"
                :error-messages="errors"
                label="Asset Number"
                required
                outlined
                dense
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Serial Number" rules="required">
              <v-text-field
                v-model="batteries.serial_number"
                :error-messages="errors"
                label="Serial Number"
                required
                outlined
                dense
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Entry Cycles" rules="required">
              <v-text-field
                v-model="batteries.entry_cycles"
                :error-messages="errors"
                label="Entry Cycles"
                required
                outlined
                dense
              >
              </v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Status" rules="required">
              <v-select
                v-model="batteries.status"
                :items="battery_status"
                :error-messages="errors"
                item-text="name"
                item-value="name"
                menu-props="auto"
                label="Status"
                outlined
                required
                dense
                :prepend-inner-icon="icons.mdiHeartPulse"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Compatible Models" rules="required">
              <v-select
                v-model="batteries.compatible_models"
                :items="aircraft_types"
                :error-messages="errors"
                item-text="name"
                item-value="name"
                menu-props="auto"
                label="Compatible Models"
                outlined
                multiple
                dense
                chips
                :prepend-inner-icon="icons.mdiEvPlugType1"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  mdiAccountOutline,
  mdiCounter,
  mdiFormatColorFill,
  mdiClockTimeEightOutline,
  mdiEvPlugType1,
  mdiHeartPulse,
} from "@mdi/js";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "BatteryAdd",
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      batteries: {},
      aircraft_types: [],
      battery_status: [],
      icons: {
        mdiAccountOutline,
        mdiCounter,
        mdiFormatColorFill,
        mdiClockTimeEightOutline,
        mdiEvPlugType1,
        mdiHeartPulse,
      },
    };
  },

  created() {
    this.dependencies();
    this.dependencieStatus();
  },

  methods: {
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.post(`${this.$IYA.URI.BATTERIES__ADD}`, this.batteries).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          }
        });
        util.isLoading();
      }
    },

    async dependencies() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_TYPES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      this.aircraft_types = data.data;
    },

    async dependencieStatus() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_STATUSES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      this.battery_status = data.data;
    },
  },
};
</script>
